// src/templates/pages/SolutionArea.js

import React, { useRef, useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Tab from '../../components/Tab.js'
import TabPanel from '../../components/TabPanel.js'
import Layout from '../../components/Layout.js'
import ResourceCards from '../../components/ResourceCards.js'
import ExpertsSpotlight from '../../layouts/ExpertsSpotlight.js'
import Assemble from '../../animations/assemble'
import Maze from '../../animations/maze'
import Mesh from '../../animations/mesh'
import ArrowSVG from '../../images/icon-arrow-right.svg'
import parse from 'html-react-parser'
import cx from 'classnames'
import { useLocation } from '@reach/router'

function AnimationPicker({ name, className }) {
  const animation = {
    assemble: Assemble,
    mesh: Mesh,
    maze: Maze,
  }

  const Anim = animation[name] ?? Mesh

  return <Anim className={className} />
}

function SolutionArea({ data }) {
  const pageData = data.wpPage.template.solutionArea
  const location = useLocation()
  let currentHash = null

  // via: https://dev.to/eevajonnapanula/keyboard-accessible-tabs-with-react-5ch4
  const tabValues = []
  pageData.relatedSolutions.map((node, index) => {
    tabValues[index] = {
      title: node.title,
      slug: node.slug,
      ref: useRef(null),
    }
  })

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedTabTitle, setSelectedTabTitle] = useState('')
  const [tabToggle, setTabToggle] = useState(true)

  const handleClick = function (index) {
    setSelectedTab(index)
    setSelectedTabTitle(tabValues[index].title)
    setTabToggle(!tabToggle)

    window.location.hash = tabValues[index].slug
  }

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect =
      selectedTab === lastTabInRound ? firstTabInRound : nextTab
    setSelectedTab(tabToSelect)
    tabValues[tabToSelect].ref.current.focus()

    window.location.hash = tabValues[tabToSelect].slug
  }

  const handleKeyPress = event => {
    const tabCount = pageData.relatedSolutions.length

    if (event.key === 'Escape') {
      setTabToggle(true)
    } else if (event.key === 'ArrowLeft') {
      const last = tabCount - 1
      const next = selectedTab - 1

      handleNextTab(last, next, 0)
    } else if (event.key === 'ArrowRight') {
      const first = 0
      const next = selectedTab + 1

      handleNextTab(first, next, tabCount - 1)
    }
  }

  useEffect(() => {
    if (location.hash) {
      currentHash = location.hash.substring(1)

      for (let i = 0, len = tabValues.length; i < len; i++) {
        if (tabValues[i].slug === currentHash) {
          setSelectedTab(i)
          setSelectedTabTitle(tabValues[i].title)

          // To bring tabs on top if user lands from different page or tab changes
          setTimeout(() => {
            tabValues[i].ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }, 800)
          tabValues[i].ref.current.focus()
        }
      }
    } else {
      setSelectedTabTitle(tabValues[selectedTab].title)
    }
  }, [currentHash, location])

  return (
    <Layout>
      <Seo post={data.wpPage} />

      {/* Page hero */}
      {/* offsets nav height manually, if this occurs in any other places, refactor to single class */}
      <section className="solution-area-intro relative -mt-nav-cancel pt-nav-cancel">
        <div className="container">
          <div className="bottom-0 flex items-center justify-center bg-rings-zen bg-contain bg-center bg-no-repeat md:absolute md:left-[45%] md:top-0 md:right-0 md:mx-0 xl:bg-cover">
            <div className="animation-wrapper w-full p-[20%] md:w-2/5 md:p-0">
              <AnimationPicker name={pageData.animation} />
            </div>
          </div>
          <div className="solution-area-intro-text py-responsive-xl-128 text-center md:w-2/5 md:text-left">
            {data.wpPage.title && (
              <h1 className="headline-xxl mb-responsive-xl-32">
                {parse(data.wpPage.title)}
              </h1>
            )}

            {pageData.pageDescription && (
              <p className="text-xl">{parse(pageData.pageDescription)}</p>
            )}
          </div>
        </div>
      </section>

      <section className="tab-wrapper !mt-0">
        {/* Solution area nav */}
        <div className="sticky top-0 z-50">
          <button
            onClick={() => setTabToggle(!tabToggle)}
            aria-expanded={!tabToggle}
            className="block w-full bg-boynton-a11y p-responsive-xl-24 text-md text-white md:hidden"
          >
            {selectedTabTitle}
            <span
              className={`ml-responsive-xl-8 inline-block origin-center ${
                tabToggle ? '-rotate-90' : 'rotate-0'
              }`}
            >
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.09985 1.5L5.09985 5.5L9.09985 1.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          <ul
            role="tablist"
            aria-label="Solutions Tabs"
            onKeyDown={handleKeyPress}
            className={`flex-col justify-center bg-boynton-a11y md:flex-row md:space-x-responsive-xl-16 lg:space-x-responsive-xl-24 ${
              tabToggle ? 'hidden md:flex' : 'flex'
            }`}
          >
            {tabValues.map((node, index) => {
              return (
                <Tab
                  id={node.slug}
                  tabPanelId={`${node.slug}-panel`}
                  index={index}
                  key={index}
                  handleChange={handleClick}
                  selectedTab={selectedTab}
                  title={node.title}
                  tabRef={node.ref}
                  classes="text-md block text-white p-responsive-xl-24 md:p-responsive-xl-16 md:py-responsive-xl-24 w-full md:w-auto"
                  selectedClasses="md:border-b-[6px] md:border-white"
                  className=""
                />
              )
            })}
          </ul>
        </div>

        {/* Solution areas */}
        {pageData.relatedSolutions.map((node, index) => {
          const nodeContent = node.solutionContent
          const heroSrc = nodeContent.subHero.image
            ? getImage(nodeContent.subHero.image.localFile)
            : null

          const expertsObj = {
            title: nodeContent.experts.headline,
            relatedBios: nodeContent.experts.relatedExperts,
          }

          const totalProcessSteps = nodeContent.process.enabled
            ? nodeContent.process.steps.length - 1
            : null

          return (
            <TabPanel
              id={`${node.slug}-panel`}
              key={index}
              tabId={node.slug}
              tabIndex={index}
              selectedTab={selectedTab}
              className="bg-noise relative space-y-responsive-xl-128 bg-white pt-responsive-xl-80"
            >
              <section className="sub-hero text-center">
                <div className="container-inner space-y-responsive-xl-32">
                  {node.title && (
                    <h2 className="headline-lg">{parse(node.title)}</h2>
                  )}

                  {/* Sub hero */}
                  {heroSrc && (
                    <GatsbyImage
                      image={heroSrc}
                      alt={nodeContent.subHero.image.altText}
                      className="max-w-[240px]"
                    />
                  )}

                  {nodeContent.subHero.description && (
                    <p className="text-lg">
                      {parse(nodeContent.subHero.description)}
                    </p>
                  )}
                </div>
              </section>

              {(nodeContent.process.enabled ||
                nodeContent.whatToExpect.enabled) && (
                <section className="process scroll-trigger space-y-responsive-xl-128 bg-light-gray py-responsive-xl-80">
                  {/* Our process */}
                  {nodeContent.process.enabled && (
                    <div
                      className={cx({
                        'container-inner px-responsive-xl-80 pb-responsive-xl-24':
                          nodeContent.process.type !== 'grid',
                        container: nodeContent.process.type === 'grid',
                      })}
                    >
                      {nodeContent.process.headline && (
                        <h2 className="scroll-target headline-lg mb-responsive-xl-80 text-center">
                          {parse(nodeContent.process.headline)}
                        </h2>
                      )}

                      {/* {nodeContent.process.type} */}

                      <ol
                        className={cx({
                          'space-y-responsive-xl-32':
                            nodeContent.process.type !== 'grid',
                          'flex flex-wrap justify-center':
                            nodeContent.process.type === 'grid',
                        })}
                      >
                        {nodeContent.process.steps.map((node, index) => {
                          const lastStep = index == totalProcessSteps
                          return (
                            <li
                              key={index}
                              className={cx('scroll-target flex', {
                                'w-full p-responsive-xl-32 text-center md:w-1/3':
                                  nodeContent.process.type === 'grid',
                              })}
                            >
                              <div
                                className={cx('relative mr-responsive-xl-32', {
                                  'pb-responsive-xl-128': !lastStep,
                                  hidden: nodeContent.process.type === 'grid',
                                })}
                              >
                                <div className="flex h-responsive-xl-80 w-responsive-xl-80 shrink-0 items-center justify-center rounded-full border-2 border-boynton-a11y text-xl font-regular text-boynton-a11y">
                                  {index + 1}
                                </div>
                                <div
                                  className={cx(
                                    'process-connector absolute bottom-0 left-1/2 top-responsive-xl-128 border-l border-boynton-a11y',
                                    { hidden: lastStep }
                                  )}
                                ></div>
                              </div>
                              <div className="process-text w-full">
                                {node.name && (
                                  <h3 className="mb-responsive-xl-16 text-xl font-medium">
                                    {parse(node.name)}
                                  </h3>
                                )}

                                {node.description && (
                                  <p className="text-sm font-regular text-fathom-full">
                                    {parse(node.description)}
                                  </p>
                                )}
                              </div>
                            </li>
                          )
                        })}
                      </ol>
                    </div>
                  )}

                  {/* What to expect */}
                  {nodeContent.whatToExpect.enabled && (
                    <div>
                      <div className="container">
                        {nodeContent.whatToExpect.headline && (
                          <h3 className="headline-md mb-responsive-xl-64 text-center">
                            {parse(nodeContent.whatToExpect.headline)}
                          </h3>
                        )}

                        <ol className="-mx-responsive-xl-16 flex flex-wrap justify-center">
                          {nodeContent.whatToExpect.benefits.map(
                            (node, index) => {
                              const imgSrc = node.image
                                ? getImage(node.image.localFile)
                                : null

                              return (
                                <li
                                  key={index}
                                  className="mb-responsive-xl-32 w-full grow-0 space-y-responsive-xl-12 px-responsive-xl-16 text-center md:w-1/3"
                                >
                                  <div className="mx-auto inline-block text-center">
                                    {imgSrc && (
                                      <GatsbyImage
                                        image={imgSrc}
                                        alt={node.image.altText}
                                        className="mb-responsive-xl-24 w-[80px]"
                                      />
                                    )}
                                  </div>

                                  {node.name && (
                                    <h3 className="text-xl font-medium">
                                      {parse(node.name)}
                                    </h3>
                                  )}

                                  {node.description && (
                                    <p className="text-sm font-regular text-fathom-full">
                                      {parse(node.description)}
                                    </p>
                                  )}
                                </li>
                              )
                            }
                          )}
                        </ol>
                      </div>
                    </div>
                  )}
                </section>
              )}

              {/* Why Arete? */}
              {nodeContent.whyArete.enabled && (
                <section className="why-arete text-center">
                  <div className="container-inner">
                    {nodeContent.whyArete.headline && (
                      <h2 className="headline-lg mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-16">
                        {parse(nodeContent.whyArete.headline)}
                      </h2>
                    )}

                    {nodeContent.whyArete.description && (
                      <p className="text-lg">
                        {parse(nodeContent.whyArete.description)}
                      </p>
                    )}
                  </div>
                </section>
              )}

              {/* Meet our experts */}
              {nodeContent.experts.enabled && (
                <section className="experts">
                  <div className="container">
                    <ExpertsSpotlight {...expertsObj} />
                  </div>
                </section>
              )}

              {/* Case study */}
              {nodeContent.caseStudy.enabled && (
                <section className="case-study">
                  <div className="container">
                    <div className="case-study-inner border border-granite-200 py-responsive-xl-48 px-responsive-xl-32 text-center">
                      <div className="mx-auto max-w-[45rem] space-y-responsive-xl-32">
                        <span className="inline-block border-b border-fuscia-full text-sm font-medium uppercase tracking-widest">
                          Case Study
                        </span>
                        {nodeContent.caseStudy.title && (
                          <h2 className="font-book">
                            {parse(nodeContent.caseStudy.title)}
                          </h2>
                        )}

                        {nodeContent.caseStudy.description && (
                          <p className="text text-left text-lg">
                            {parse(nodeContent.caseStudy.description)}
                          </p>
                        )}

                        {nodeContent.caseStudy.relatedArticle && (
                          <Link
                            to={nodeContent.caseStudy.relatedArticle.uri}
                            className="btn btn-primary mt-responsive-xl-32"
                          >
                            Read More
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {/* Related resources */}
              {nodeContent.relatedResources && (
                <section className="resources">
                  <div className="container">
                    <h3 className="mb-responsive-xl-64 text-center text-sm font-medium uppercase tracking-[0.2em] text-granite-600">
                      Related Resources
                    </h3>
                    <ResourceCards
                      manualResources={nodeContent.relatedResources}
                    />
                  </div>
                </section>
              )}
            </TabPanel>
          )
        })}
      </section>

      {/* Audiences */}
      <section className="audiences bg-boynton-dark py-responsive-xl-80 text-white">
        <div className="container">
          <h2 className="mb-responsive-xl-64 text-center">
            Arete Provides Solutions For:
          </h2>
          <ul className="grid grid-cols-1 gap-responsive-xl-32 md:grid-cols-2 lg:grid-cols-3">
            {pageData.relatedAudience.map((node, index) => {
              return (
                <li
                  key={index}
                  className="relative space-y-responsive-xl-16 bg-boynton-a11y p-responsive-xl-32 transition-transform md:hover:scale-[1.0125]"
                >
                  {node.audience && (
                    <h3 className="text-xl font-medium">
                      {parse(node.audience)}
                    </h3>
                  )}

                  {node.description && (
                    <p className="text-sm">{parse(node.description)}</p>
                  )}

                  <p>
                    <Link
                      to={node.relatedPage.uri}
                      className="btn-text btn-arrow inline-flex items-center text-sm text-white after:absolute after:inset-0 after:block after:content-[''] hover:text-current"
                    >
                      {node.relatedPage.title}
                      <ArrowSVG className="icon ml-responsive-xl-4 fill-current" />
                    </Link>
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default SolutionArea

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        ... on WpTemplate_SolutionArea {
          solutionArea {
            pageDescription
            animation
            relatedAudience {
              audience
              description
              relatedPage {
                ... on WpPage {
                  id
                  uri
                  title
                }
              }
            }
            relatedSolutions {
              ... on WpSolution {
                id
                title
                slug
                solutionContent {
                  subHero {
                    description
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 480
                            placeholder: TRACED_SVG
                            formats: [AUTO, WEBP, AVIF]
                            layout: CONSTRAINED
                            outputPixelDensities: [1, 2]
                          )
                        }
                      }
                    }
                  }
                  process {
                    enabled
                    headline
                    type
                    steps {
                      name
                      description
                    }
                  }
                  whatToExpect {
                    enabled
                    headline
                    benefits {
                      name
                      description
                      image {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              width: 160
                              height: 160
                              placeholder: TRACED_SVG
                              formats: [AUTO, WEBP, AVIF]
                              layout: CONSTRAINED
                              outputPixelDensities: [1, 2]
                            )
                          }
                        }
                      }
                    }
                  }
                  whyArete {
                    enabled
                    headline
                    description
                  }
                  experts {
                    enabled
                    headline
                    relatedExperts {
                      ... on WpBio {
                        uri
                        bio {
                          firstName
                          lastName
                          role
                          description
                          image {
                            altText
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 320
                                  height: 320
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP, AVIF]
                                  transformOptions: { cropFocus: CENTER }
                                )
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  caseStudy {
                    enabled
                    title
                    description
                    relatedArticle {
                      ... on WpArticle {
                        uri
                      }
                    }
                  }
                  relatedResources {
                    ... on WpArticle {
                      id
                      uri
                      title
                      content
                      contentTypeName
                      article {
                        description
                        thumbnailColor
                        heroImage {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                height: 230
                                width: 350
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                        }
                      }
                    }
                    ... on WpPodcast {
                      id
                      uri
                      title
                      content
                      contentTypeName
                      podcast {
                        description
                        thumbnailColor
                        heroImage {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                height: 230
                                width: 350
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                        }
                      }
                    }
                    ... on WpReport {
                      id
                      uri
                      title
                      content
                      contentTypeName
                      report {
                        description
                        thumbnailColor
                        heroImage {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                height: 230
                                width: 350
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                        }
                      }
                    }
                    ... on WpWebinar {
                      id
                      uri
                      title
                      content
                      contentTypeName
                      webinar {
                        description
                        thumbnailColor
                        heroImage {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                height: 230
                                width: 350
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                transformOptions: { cropFocus: CENTER }
                              )
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
