// src/components/ResourceCard.js

import React from 'react'
import ResourceCard from '../components/ResourceCard.js'
import cx from 'classnames'
import PropTypes from 'prop-types'

function ResourceCards(props) {
  const manualResources = props.manualResources || null
  const automatedResources = props.automatedResources || null

  const relatedResources = function () {
    const resources = []

    if (manualResources) {
      // hand-picked resources
      manualResources.map(node =>
        resources.push({
          contentType: node.contentTypeName,
          description: node[node.contentTypeName].description,
          content: node.content,
          uri: node.uri,
          title: node.title,
          heroImage: node[node.contentTypeName].heroImage,
          thumbnailColor: node[node.contentTypeName].thumbnailColor,
        })
      )
    } else {
      // automated resources
      automatedResources.edges.map(node =>
        resources.push({
          contentType: node.node.contentTypeName,
          description: node.node[node.node.contentTypeName].description,
          content: node.node.content,
          uri: node.node.uri,
          title: node.node.title,
          heroImage: node.node[node.node.contentTypeName].heroImage,
          thumbnailColor: node.node[node.node.contentTypeName].thumbnailColor,
        })
      )
    }

    return resources
  }

  return (
    <section className="resourceCards scroll-trigger">
      <ul
        className={cx('', {
          'flex flex-wrap md:-mx-responsive-xl-16': !props.isList,
          'space-y-responsive-xl-32': props.isList,
        })}
      >
        {relatedResources().map((node, index) => {
          return (
            <li
              key={index}
              className={cx({
                'w-full px-responsive-xl-16 pb-responsive-xl-32 md:w-1/3':
                  !props.isList,
              })}
            >
              <ResourceCard
                featured={false}
                type={node.contentType}
                description={node.description}
                content={node.content}
                uri={node.uri}
                title={node.title}
                heroImage={node.heroImage}
                thumbnailColor={node.thumbnailColor}
                isList={props.isList}
              />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default ResourceCards

ResourceCards.propTypes = {
  manualResources: PropTypes.array,
  isList: PropTypes.bool,
}
