// src/components/TaPanel.js
// via: https://dev.to/eevajonnapanula/keyboard-accessible-tabs-with-react-5ch4

import React from 'react'

function TaPanel({ id, tabId, selectedTab, tabIndex, children, className }) {
  return (
    <div
      role="tabpanel"
      id={id}
      aria-labelledby={tabId}
      hidden={selectedTab !== tabIndex}
      tabIndex={0}
      className={className}
    >
      {children}
    </div>
  )
}

export default TaPanel
