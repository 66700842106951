// src/components/Tab.js
// via: https://dev.to/eevajonnapanula/keyboard-accessible-tabs-with-react-5ch4

import React from 'react'

function Tab({
  id,
  index,
  selectedTab,
  tabPanelId,
  title,
  handleChange,
  tabRef,
  classes,
  selectedClasses,
  parentClasses,
  style,
}) {
  const handleClick = () => handleChange(index)

  return (
    <li role="presentation" className={'body-md font-regular ' + parentClasses}>
      <button
        role="tab"
        className={
          selectedTab === index ? `${classes} ${selectedClasses}` : classes
        }
        id={id}
        data-slug={id}
        aria-selected={selectedTab === index}
        aria-controls={tabPanelId}
        onClick={handleClick}
        tabIndex={selectedTab === index ? 0 : -1}
        ref={tabRef}
        style={selectedTab === index ? style : {}} // Apply inline style only for the selected tab
      >
        {title}
      </button>
    </li>
  )
}

export default Tab
