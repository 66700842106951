// src/layouts/ExpertsSpotlight.js

import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import RingSVG from '../images/ring-single.svg'
import ArrowSVG from '../images/icon-arrow-right.svg'

function ExpertsSpotlight({ title, relatedBios }) {
  return (
    <div>
      {title && (
        <h2 className="headline-md mb-responsive-xl-64 text-center">
          {parse(title)}
        </h2>
      )}

      <ul className="flex flex-wrap justify-center">
        {relatedBios.map((node, index) => {
          const image = node.bio.image
            ? getImage(node.bio.image.localFile)
            : null

          return (
            <li
              key={index}
              className="expert-item hover-white-trigger hover:bg-noise w:full mb-responsive-xl-32 text-center transition-colors hover:bg-boynton-a11y md:w-1/3"
            >
              <div className="expert-inner px-responsive-xl-32 py-responsive-xl-64">
                {image && (
                  <div className="expert-image relative mx-auto mb-responsive-xl-16 w-2/5">
                    <GatsbyImage
                      image={image}
                      alt=""
                      className="relative z-40 w-full rounded-full"
                    />
                    <div className="ring-accent absolute -inset-responsive-xl-24">
                      <RingSVG className="w-full" />
                    </div>
                  </div>
                )}
                <div className="expert-text">
                  <p className="hover-white-target mb-responsive-xl-4 font-medium text-xl">
                    {node.bio.firstName} {node.bio.lastName}
                  </p>
                  <div className="hover-white-target text-meta-md uppercase tracking-wider text-granite-600">
                    {node.bio.role}
                  </div>
                  {node.bio.description && (
                    <p className="hover-white-target my-responsive-xl-16 text-sm line-clamp-5">
                      {node.bio.description.replace(/(<([^>]+)>)/gi, '')}
                    </p>
                  )}
                  <Link
                    to={node.uri}
                    className="btn-arrow hover-white-target inline-flex items-center font-medium text-[0.875rem]"
                  >
                    <span>Read Bio</span>
                    <ArrowSVG className="icon ml-responsive-xl-4 fill-fuscia-full" />
                  </Link>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ExpertsSpotlight
